<template>
  <card>
    <template v-slot:table>
      <v-form @submit.prevent="sendValues">
        <div class="form-register">
          <h2 class="title">
            Informe quais resíduos são recolhidos por essa empresa:
          </h2>
          <residues-options @collectionItems="setResidues" />
        </div>

        <v-divider></v-divider>

        <v-card-actions>
          <v-btn
            @click="$router.push({ name: 'users' })"
            color="#ffffff"
            class="cancel-button black-3--text"
            >{{ $t.root.actionButtonLabels.exit }}</v-btn
          >
          <v-btn
            type="submit"
            :disabled="residues.length <= 0"
            color="secondary-color-1"
            class="next-step-button white--text"
            >{{ $t.root.actionButtonLabels.send }}</v-btn
          >
        </v-card-actions>
      </v-form>
    </template>
  </card>
</template>

<script>
  import { mapActions } from "vuex";

  import Card from "@/modules/core/views/templates/CardLayout";
  import ResiduesOptions from "@/modules/recycler/views/components/ResiduesOptions";

  export default {
    components: {
      Card,
      ResiduesOptions
    },

    data: () => ({
      residues: []
    }),

    methods: {
      ...mapActions(["toggleSnackbar"]),
      ...mapActions("recycler", ["updateRecycler"]),

      setResidues(collection) {
        this.residues = collection;
      },

      async sendValues() {
        const id = this.$route.params.id;
        const payload = {
          id,
          body: { residues: this.residues }
        };
        try {
          await this.updateRecycler(payload);
          this.$router.push({ name: "addDocument", params: { id } });
        } catch (err) {
          this.toggleSnackbar({
            text: "Algo deu errado.",
            type: "error"
          });
        }
      }
    }
  };
</script>

<style lang="scss" scoped>
  @import "@/assets/stylesheets/styleguide";
  @import "@/assets/stylesheets/forms";
  @import "@/assets/stylesheets/components";

  @include form-register();
  @include container-attachment();

  ::v-deep .row.container-list-attachment {
    height: 40px !important;
  }
  .title {
    font-size: 16px;
    padding: 24px;
  }
  ::v-deep .v-toolbar--flat {
    display: none !important;
  }
</style>
