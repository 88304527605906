<template>
  <div>
    <v-expansion-panels focusable class="panel">
      <v-expansion-panel v-for="(item, i) in residuesType" :key="i">
        <v-expansion-panel-header :hide-actions="true">
          <div>
            <v-icon medium class="icon">mdi-arrow-up-drop-circle</v-icon>
            {{ item.description }}
          </div>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-layout row wrap>
            <v-checkbox
              v-for="(res, i) in item.residues"
              :key="i"
              :multiple="true"
              v-model="selected"
              :label="res.description"
              :value="{ id: res.id }"
            ></v-checkbox>
          </v-layout>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </div>
</template>
<script>
  import { mapActions } from "vuex";

  export default {
    props: ["residues"],
    watch: {
      selected(val) {
        this.$emit("collectionItems", this.selected);
      }
    },
    data: () => ({
      selected: [],
      residuesType: []
    }),

    async mounted() {
      await this.getResiduesMethod();
      if (this.residues.length > 0) {
        const residuesfilter = this.residues.filter(residue => {
          this.selected.push({ id: residue.id });
        });
      }
    },

    methods: {
      ...mapActions(["toggleSnackbar"]),
      ...mapActions("recycler", ["getResidues"]),

      async getResiduesMethod(resType) {
        try {
          const { data } = await this.getResidues();

          this.residuesType = data;
        } catch (err) {
          this.toggleSnackbar({
            text: "Algo deu errado.",
            type: "error"
          });
        }
      },

      async setResidues(value) {
        await this.getResiduesMethod(value);
      }
    }
  };
</script>
<style lang="scss" scopped>
  .icon {
    color: #90a4ae !important;
    margin-right: 15px;
  }
  .panel {
    padding: 24px;
  }
</style>
